import React from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import { DashboardOutlined, LogoutOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Menu, Button } from 'antd';
import { logout } from '../../services/Partner';
import './index.less';

function AppBar() {
  const location = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const onClick = () => {
    logout();
    localStorage.removeItem('sessionId');
    queryClient.clear();
    history.push('/auth');
  };

  return (
    <div id="AppBar">
      <div className="Header_inner">
        <div className="AppBar_logo">
          <img
            src="/logo.png"
            alt="buddies logo"
          />
        </div>
        <Menu
          mode="horizontal"
          selectedKeys={[location.pathname.split('/')[1]]}
        >
          <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
            <a href="#/dashboard">Partner Dashboard</a>
          </Menu.Item>
        </Menu>
        <div className="Fill" />
        <Button
          icon={<LogoutOutlined />}
          onClick={onClick}
        >
          Log out

        </Button>
      </div>

    </div>
  );
}

export default AppBar;
