import React from 'react';
import { message } from 'antd';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { resetPassword } from '../../services/Partner';
import AuthForm from '../../components/AuthForm';
import handleLogin from '../../utils/handle-login';

function ResetPassword() {
  const history = useHistory();
  const location = useLocation();
  const { token } = useParams();

  const onFinish = async (values) => {
    try {
      const { _id: sessionId } = await resetPassword(token, values);
      handleLogin(sessionId, history, location);
    } catch (error) {
      message.error('Invalid or expired token.');
    }
  };

  return (
    <div className="Login_wrapper">
      <div className="Login_inner">
        <AuthForm
          title="Reset Password"
          onFinish={onFinish}
          inputs={['password', 'passwordConfirmation']}
        />
      </div>
    </div>
  );
}

export default ResetPassword;
