import React from 'react';
import { Alert, message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { register } from '../../services/Partner';
import AuthForm from '../../components/AuthForm';
import handleLogin from '../../utils/handle-login';

function Register() {
  const history = useHistory();
  const location = useLocation();

  const onFinish = async (values) => {
    try {
      const { _id: sessionId } = await register(values);
      handleLogin(sessionId, history, location);
    } catch (error) {
      message.error('Unknown error. Please contact support@buddieshr.com!');
    }
  };

  return (
    <div className="Register_container">
      <div className="Register_wrapper">
        <div className="Register_left">
          <h1 className="bold">Partner rules</h1>
          <Alert message="Please read carefully, if you have any question, you can reach out at support@buddieshr.com" type="info" />
          <br />
          <div>
            <ol
              className="Partner_rules"
            >
              <li>
                {'You earn 50% commissions on all payments coming from Slack workspaces that installed our apps through one of your links. '}
              </li>
              <li>
                The minimum amount necessary for the payout is $50.
                PayPal transfer if you are a professional (invoice required from you),
                Amazon gift card otherwise.
              </li>
              <li>
                This is not for a personal use. Every personal account used
                will end up by a removal of the affiliate account.
              </li>
            </ol>
          </div>
        </div>
        <div className="Register_right">
          <AuthForm
            cta="Register"
            title="Register as partner"
            onFinish={onFinish}
            inputs={['email', 'password', 'passwordConfirmation']}
            links={[
              { url: '#/auth/login', text: 'Already have an account?' },
            ]}
          />
        </div>
      </div>
    </div>

  );
}

export default Register;
