/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable max-len */
import React, { useContext } from 'react';
import moment from 'moment';
import {
  Empty, Table, Typography,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { fetchData } from '../../services/Partner';
import ProfileContext from '../../contexts/ProfileContext';
import './Dashboard.less';

const { Paragraph } = Typography;

function Dashboard() {
  const {
    isLoading,
    data,
  } = useQuery(['fetchData'], fetchData);
  const profile = useContext(ProfileContext);
  let stats;
  if (data) {
    const total = (data.totalPayments / 100).toFixed(2);
    const paid = (profile.payments.reduce((t, p) => t + p.amount, 0) / 100).toFixed(2);
    stats = {
      total,
      paid,
      // "cagnotte"
      pending: total - paid,
    };
  }
  const events = data?.events || [];
  return (
    <div id="Dashboard">
      <div className="Content_wrapper">
        <div className="Top_panels">
          <div className="Top_panel_left">
            <h1 className="bold">Links</h1>
            <div className="Link_wrapper">
              {['buddieshr', 'alfymatching', 'billybirthday', 'clappykudos', 'pulsysurvey'].map((key) => (
                <div key={key} style={{ marginBottom: 8 }}>
                  <Paragraph
                    style={{ marginBottom: '0px' }}
                    code
                    copyable
                  >
                    {`https://${key}.com/?ref=${profile._id}`}
                  </Paragraph>
                </div>
              ))}
            </div>
            <div style={{ fontStyle: 'italic', color: 'white' }}>
              <InfoCircleOutlined />
              {' Share your link to start tracking installations'}
            </div>
          </div>
          <div className="Top_panel_right">
          <h1 className="bold">Balance</h1>
            <div style={{ color: 'white' }}>
              <div className="Balance_total">{`$${stats?.total || '-'}`}</div>
              <div style={{ displayay: 'flex' }}>
                <div>
                  {`Paid: $${stats?.paid || '-'}`}
                </div>
                <div>
                  {`Payment pending: $${stats?.pending || '-'}`}
                </div>
                <div style={{ fontStyle: 'italic' }}>
                  <InfoCircleOutlined />
                  &nbsp;Minimum payout is $50, send an email at support@buddieshr.com to claim
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Table_wrapper">
        <h2 className="bold">Events History</h2>
        <Table
          dataSource={events}
          loading={isLoading}
          rowKey="_id"
          size="small"
          locale={{
            emptyText: (
              <div>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No installations/payments yet, share your links to start collecting commissions!"
                />
              </div>
            ),
          }}
        >
          <Table.Column
            title={events.length > 0 ? 'Date' : ''}
            key="_id"
            render={(_, e) => moment(e.date).format('YYYY/MM/DD')}
          />
          <Table.Column
            title={events.length > 0 ? 'Event' : ''}
            key="_id"
            render={(_, e) => ({
              install: 'A new Slack workspace installed one of our apps for the first time 🎉',
              payment: `A payment of $${(e.amount / 100).toFixed(2)} has been made by one of your referrals 💸`,
            }[e.type] || e.type)}
          />
          <Table.Column
            title={events.length > 0 ? 'Commission' : ''}
            dataIndex="amount"
            key="_id"
            render={(amount) => (amount ? `$${(profile.percentage * (amount / 100)).toFixed(2)}` : '-')}
          />
        </Table>
        </div>

      </div>
    </div>
  );
}

export default Dashboard;
