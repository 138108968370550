import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Dashboard from './Dashboard';
import r from '../../utils/wrap-route-component';
import { getProfile } from '../../services/Partner';
import AppBar from '../../components/AppBar';
import Loader from '../../components/Loader';
import ProfileContext from '../../contexts/ProfileContext';

function AppWrapper() {
  const location = useLocation();

  const redirectUrl = `/auth/login?next=${encodeURIComponent(JSON.stringify(location))}`;

  const hasSessionId = !!localStorage.getItem('sessionId');
  if (!hasSessionId) {
    return <Redirect to={redirectUrl} />;
  }

  const { isLoading, data, error } = useQuery(['getProfile'], getProfile, { retry: false });
  if (error) {
    localStorage.removeItem('sessionId');
    window.location.reload(); // weird but fix a bug
    return null;
  }

  return (
    <ProfileContext.Provider value={data}>
      <AppBar />
      {isLoading ? <Loader /> : null}
      <div className="PageWrapper">
        <Switch>
          <Route path="/dashboard" exact render={r(Dashboard, !!data, data)} />
          <Redirect from="*" to="/dashboard" />
        </Switch>
      </div>
    </ProfileContext.Provider>
  );
}

export default AppWrapper;
