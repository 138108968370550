import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { message } from 'antd';
import { login } from '../../services/Partner';
import AuthForm from '../../components/AuthForm';
import handleLogin from '../../utils/handle-login';

function Login() {
  const history = useHistory();
  const location = useLocation();

  const onFinish = async (values) => {
    try {
      const { _id: sessionId } = await login(values);
      handleLogin(sessionId, history, location);
    } catch (error) {
      message.error('Wrong email/password.');
    }
  };

  return (
    <div className="Login_wrapper">
      <div className="Login_inner">
        <AuthForm
          cta="Login"
          onFinish={onFinish}
          links={[
            { url: '#/auth/register', text: 'No account yet? Click here to register' },
            { url: '#/auth/forget-password', text: 'Forgot your password?' },
          ]}
        />
      </div>
    </div>
  );
}

export default Login;
