import React from 'react';
import {
  HashRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import AuthLogin from './pages/auth/Login';
import AuthRegister from './pages/auth/Register';
import ForgetPassword from './pages/auth/ForgetPassword';
import ResetPassword from './pages/auth/ResetPassword';
import AppWrapper from './pages/app/AppWrapper';
import r from './utils/wrap-route-component';

function Routing() {
  return (
    <HashRouter>
      <Switch>
        <Route path="/auth/login" exact render={r(AuthLogin)} />
        <Route path="/auth/register" exact render={r(AuthRegister)} />
        <Route path="/auth/forget-password" exact render={r(ForgetPassword)} />
        <Route path="/reset-password/:token" exact render={r(ResetPassword)} />
        <Route path="/" render={r(AppWrapper)} />
        <Redirect from="*" to="/" />
      </Switch>
    </HashRouter>
  );
}

export default Routing;
