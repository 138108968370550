import Api from './Api';

export const login = async (values) => {
  const result = await Api.post('/partners/login', values);
  return result.data;
};

export const logout = async () => {
  Api.post('/partners/logout');
};

export const register = async (values) => {
  const result = await Api.post('/partners/register', values);
  return result.data;
};

export const forgetPassword = async (values) => {
  const result = await Api.post('/partners/forget-password', values);
  return result.data;
};

export const resetPassword = async (resetPasswordToken, values) => {
  const result = await Api.post('/partners/reset-password', {
    resetPasswordToken,
    ...values,
  });
  return result.data;
};

export const getProfile = async () => {
  const result = await Api.post('/partners/get-profile');
  return result.data;
};

export const fetchData = async () => {
  const result = await Api.post('/partners/fetch-data');
  return result.data;
};
