import qs from 'qs';

export default (sessionId, history, location) => {
  localStorage.setItem('sessionId', sessionId);
  const search = qs.parse(location.search || '', { ignoreQueryPrefix: true });
  const param = search.next
    ? JSON.parse(search.next)
    : '/';
  history.push(param);
};
