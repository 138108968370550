import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Checkbox, Form, Input,
} from 'antd';
import './index.less';

function AuthForm({
  onFinish,
  inputs,
  title,
  cta,
  links,
  showTermsApproval,
}) {
  const [loading, setLoading] = useState(false);
  const onFinishWithLoading = async (values) => {
    setLoading(true);
    await onFinish(values);
    setLoading(false);
  };
  return (
    <div>
      <div>
        <img
          src="/logo_with_background.png"
          alt="buddies hr logo"
          className="Login_logo"
        />
      </div>
      <h1>{title}</h1>
      <Form
        onFinish={onFinishWithLoading}
        autoComplete="off"
        layout="vertical"
      >
        {inputs.includes('email') && (
          <Form.Item
            label="Email"
            name="email"
            rules={[{
              required: true,
              type: 'email',
              message: 'Please enter a valid email!',
            }]}
          >
            <Input />
          </Form.Item>
        )}
        {inputs.includes('password') && (
          <Form.Item
            label="Password"
            name="password"
            rules={[{
              required: true,
              ...(inputs.includes('passwordConfirmation') ? {
                min: 6,
              } : {}),
            }]}
          >
            <Input.Password />
          </Form.Item>
        )}
        {inputs.includes('passwordConfirmation') && (
          <Form.Item
            label="Password (Confirmation)"
            name="passwordConfirmation"
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        )}
        {showTermsApproval && (
          <Form.Item
            name="terms"
            valuePropName="checked"
            rules={[{
              required: true,
              message: 'Please accept the terms and conditions',
            }]}
          >
            <Checkbox>
              I accept the
              {' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://buddieshr.com/terms-conditions"
              >
                Terms and condition
              </a>

            </Checkbox>
          </Form.Item>
        )}
        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            {cta}
          </Button>
        </Form.Item>
      </Form>
      <div>
        {links.map((link) => (
          <div key={link.url} className="LinkItem">
            <a href={link.url}>{link.text}</a>
          </div>
        ))}
      </div>
    </div>
  );
}

AuthForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  inputs: PropTypes.arrayOf(PropTypes.oneOf(['email', 'password', 'passwordConfirmation'])),
  title: PropTypes.string,
  cta: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
  showTermsApproval: PropTypes.bool,
};

AuthForm.defaultProps = {
  inputs: ['email', 'password'],
  title: 'Partner Dashboard',
  cta: 'Submit',
  links: [],
  showTermsApproval: false,
};

export default AuthForm;
